import React from 'react'

import './accreditations.css'

const Accreditations = () => (
  <div className="accreditations">
    <img
      className="lrg"
      src={require('./trustmark.jpg')}
      alt="TrustMark Approved"
      title="TrustMark Approved"
    />
    <img
      src={require('./stroma.png')}
      alt="STROMA Certified"
      title="STROMA Certified"
    />
    <img
      className="dbs"
      src={require('./dbs.jpg')}
      alt="DBS Checked"
      title="DBS Checked"
    />
    <img
      className="lrg"
      src={require('./environment.jpg')}
      alt="Environment Agency Registered"
      title="Environment Agency Registered"
    />
  </div>
)

export default Accreditations
